.own .BMap_bubble_pop {
    top: 100px !important;
    padding:0 !important;
}

.own .BMap_bubble_pop .BMap_bubble_top {
    display: none !important;
}

.own .BMap_bubble_pop .BMap_bubble_top .BMap_bubble_title {
    display: none !important;
}

.own .BMap_bubble_pop .BMap_bubble_center .BMap_bubble_content {
    top: 0 !important;
    line-height: normal !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    overflow-y: auto;

}
.own .BMap_bubble_pop .BMap_bubble_center .BMap_bubble_content ::-webkit-scrollbar{
    display:none !important;
}

.own .BMap_bubble_pop img {
    /* display: none; */
    top: 81px !important;

}