.own .video-react .video-react-big-play-button{
    height: 4.5rem;
    width: 4.5rem;
    border-radius: 50%;
    line-height: 4.5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    border:none;
}
